import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/deutschland-wo-brennts/deutschland-wo-brennts/src/templates/default.jsx";
import Sharing from '../components/sharing/sharing.jsx';
import { Link, LinkList } from '../components/link/link.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Am 23. Februar wird ein neuer Bundestag gewählt und wir möchten wissen: Was bewegt euch vor der Wahl? Welche Sorgen habt ihr? Welche Probleme müssen in Deutschland dringend gelöst werden? Macht mit bei unserer Befragung und sagt es uns!`}</p>
    <p>{`Wir machen eure Wünsche, Gedanken und Geschichten sichtbar!`}</p>
    <iframe src="https://wdrugcv2.blob.core.windows.net/wobrennts-799/wobrennts.html" className="survey" scrolling="auto" title="Deutschland, wo brennt's?" id="wobrennts-iframe" frameBorder="0" loading="lazy"></iframe>
    <p>{`Ihr wisst, wo`}{`’`}{`s brennt. Deshalb wollen wir eure Probleme und eure Ratschläge an die Politik herantragen und sie zum Mittelpunkt des Wahlkampfs machen. Ihr findet die Ergebnisse vor der Bundestagswahl hier auf wdr.de und der App WDR aktuell, im WDR Fernsehen, im Radio und auf Social Media.`}</p>
    <LinkList links={[{
      title: "Die jungen Formate aus dem WDR Newsroom",
      href: "https://www1.wdr.de/nachrichten/junge-formate/index.html"
    }, {
      title: "Sendungen und digitale Formate aus dem WDR Newsroom",
      href: "https://www1.wdr.de/nachrichten/newsroom-sendungen-angebote-100.html"
    }, {
      title: "Bleib auf dem Laufenden mit der neuen WDR aktuell App",
      href: "https://www1.wdr.de/nachrichten/meins-wdr-aktuell-app-update-100.html"
    }]} mdxType="LinkList" />
    <Sharing twitter facebook mail whatsapp telegram reddit xing linkedin mdxType="Sharing" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      