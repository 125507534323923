function getStarterConfig() {
  switch (process.env.GATSBY_STARTER_CONFIG) {
    case "netlify":
      return {
        pathPrefix: "/",
        origin: "https://deutschland-wo-brennts.netlify.app",
      };
    case "production":
    default:
      return {
        pathPrefix: "/ddj/deutschland-wo-brennts/",
        origin: "https://data.wdr.de",
      };
  }
}

module.exports = getStarterConfig();
