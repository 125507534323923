import React from "react";
import classNames from "class-names";
import Logo from "../logo/logo.jsx";
import styles from "./header.module.css";

const Header = ({ heroImage = "", heroImageMobile = "", heroAlt = "", heroCredit = "" }) => {
  const hasHero = heroImage !== "";
  const hasHeroMobile = heroImageMobile !== "";
  return (
    <header
      className={classNames(styles.header, { [styles.hasHero]: hasHero })}
    >
      <div className={styles.stripe}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
        </div>
      </div>
      {hasHero && (
        <>
          <img className={classNames(styles.hero, {[styles.heroDesktop]: hasHeroMobile})} src={heroImage} alt={heroAlt} />
          {
            hasHeroMobile && <img className={classNames(styles.hero, styles.heroMobile)} src={heroImageMobile} alt={heroAlt} />
          }
          {heroCredit !== "" && (
            <span className={styles.credit}>{heroCredit}</span>
          )}
        </>
      )}
    </header>
  );
};

export default Header;
